import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from '@app/api';
import { IUser } from '@modules/auth';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  keepUnusedDataFor: 0,
  baseQuery: buildBaseQuery(''),
  endpoints: (builder) => ({
    getProfile: builder.query<{
      data: IUser;
    }, void>({
      query: () => '/profile',
    }),
    deleteProfile: builder.mutation<void, string>({
      query: (password) => ({
        url: '/profile',
        method: 'DELETE',
        body: { password },
      }),
    }),
  }),

});

export const { useDeleteProfileMutation,
  useGetProfileQuery,
  useLazyGetProfileQuery } = profileApi;
