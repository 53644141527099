import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import Login from '@pages/Login';
import { MyAccount } from '@pages/Auth';
import GuestLayout from '@layouts/GuestLayout';
import AuthLayout from '@layouts/AuthLayout';

const router = createBrowserRouter([
  {
    path: '',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <MyAccount />,
      },
    ],
  },
  {
    path: '',
    element: <GuestLayout />,
    children: [
      {
        path: '*',
        element: <Login />,
      },
    ],
  },
]);

export default router;
