import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import {
  getAuthInitialState,
  purgeAuthDataFromStorage,
  saveTokenInStorage,
  saveUserDataInStorage,
} from '../helpers/authHelpers';
import { IAuthStore, IUser } from '../types';

const initialState: IAuthStore = getAuthInitialState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<{ accessToken: string }>) => {
      saveTokenInStorage(payload.accessToken);

      state.accessToken = payload.accessToken;
    },
    setUser: (state, { payload }: PayloadAction<{ user: IUser }>) => {
      saveUserDataInStorage(payload);

      state.userInfo.user = payload.user;
    },
    logout: (state) => {
      purgeAuthDataFromStorage();
      state.accessToken = '';
    },
  },
});

export const { setToken, setUser, logout } = authSlice.actions;
