import Header from '@components/Header';
import { Navigate, Outlet } from 'react-router';
import { useAppSelector } from '@app/store';
import { PRIVATE_PAGES } from '@app/router/pages';

import { LayoutContainer } from '@layouts/styled';

const GuestLayout = () => {
  const { accessToken } = useAppSelector(({ auth }) => auth);

  if (accessToken) {
    return <Navigate to={PRIVATE_PAGES.HOME} />;
  }

  return (
    <LayoutContainer>
      <Header />

      <Outlet />
    </LayoutContainer>
  );
};

export default GuestLayout;
