import React from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppSelector } from '@app/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { logout } from '@modules/auth';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

import { EditProfileContainer, Inputs, SaveButton, StyledTextField } from './styled';
import { DeleteProfileFormValidation } from '../../helpers/delete-profile-helpers';
import useAlert from '@hooks/useAlert';
import { useDeleteProfileMutation } from '../../api/profile.api';
import usePasswordInput from '@hooks/usePasswordInput';

interface IDeleteProfileFormValues {
  password: string;
}

const DeleteProfile = () => {
  const { showSuccessToast, showErrorToast } = useAlert();
  const dispatch = useDispatch();
  const { userInfo } = useAppSelector(({ auth }) => auth);

  const {
    inputType,
    inputProps,
  } = usePasswordInput();

  const [deleteProfile] = useDeleteProfileMutation();

  const { handleSubmit, control, formState: { errors } } = useForm<IDeleteProfileFormValues>({
    resolver: yupResolver(DeleteProfileFormValidation),
    defaultValues: {
      password: '',
    },
  });

  const submitHandler: SubmitHandler<IDeleteProfileFormValues> = async (data) => {
    try {
      await deleteProfile(data.password).unwrap();

      showSuccessToast('The profile info has been deleted');

      dispatch(logout());
    } catch (e: any) {
      if (e.data.error === 'INVALID_CREDENTIALS') {
        showErrorToast('The current password is incorrect');

        return;
      }

      showErrorToast('Something went wrong, try again later');
    }
  };

  return (
    <EditProfileContainer component="form" onSubmit={handleSubmit(submitHandler)}>

      <Typography maxWidth="40%" textAlign="center" marginBottom={4}>
        To delete your profile please fill in the current password.
        User with email
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {` ${userInfo.user.email} `}
        </span>
        will be deleted
      </Typography>
      <Inputs>
        <Controller
          name="password"
          control={control}
          render={({
            field: {
              onChange,
              onBlur,
              value,
              name,
            },
          }) => (
            <StyledTextField
              label="Password"
              placeholder="Password"
              fullWidth
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors?.password?.message}
              helperText={errors?.password?.message}
              type={inputType}
              InputProps={inputProps}
            />
          )}
        />
      </Inputs>

      <SaveButton
        color="error"
        variant="contained"
        type="submit"
      >
        Delete profile
      </SaveButton>
    </EditProfileContainer>
  );
};

export default DeleteProfile;
