import { AuthStorageKeys } from '../constants';
import { IAuthStore, IUser } from '../types';

const getUserDataStorageType = () => localStorage;

export const getAuthInitialState = (): IAuthStore => {
  const storage = getUserDataStorageType();

  return {
    accessToken: storage.getItem(AuthStorageKeys.ACCESS_TOKEN) || '',
    userInfo: JSON.parse(storage.getItem(AuthStorageKeys.USER) as string) || {},
  };
};

export const saveUserDataInStorage = (payload: { user?: IUser }) => {
  const storage = getUserDataStorageType();

  storage.setItem(AuthStorageKeys.USER, JSON.stringify({
    user: payload.user,
  }));
};

export const saveTokenInStorage = (accessToken: string) => {
  const storage = getUserDataStorageType();

  storage.setItem(AuthStorageKeys.ACCESS_TOKEN, accessToken);
};

export const purgeAuthDataFromStorage = () => {
  const storage = getUserDataStorageType();

  storage.removeItem(AuthStorageKeys.ACCESS_TOKEN);
  storage.removeItem(AuthStorageKeys.USER);
};
