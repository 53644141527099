import { Button, Grid, css, styled, Box, Typography } from '@mui/material';
import COLORS from '@app/colors';

export const HeaderContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
      justify-content: space-between;
      height: unset;
    }
  `}
`;

export const LogoBox = styled(Grid)`
  cursor: pointer;
  img {
    width: 200px;
  }
  
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      img {
        height: 25px;
      }
    }
  `}
`;

export const Logo = styled(Box)`
  cursor: pointer;
`;

export const MiddleBox = styled(Grid)`
  order: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 3;
      margin-top: 40px;
    }
  `}
`;

export const LinksBox = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
  order: 3;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      order: 2;
      justify-content: end;
    }
  `}
`;

export const HeaderButton = styled(Button)`
  font-size: 13px;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;

  &.MuiButton-outlined {
    border: 1px solid ${COLORS.silver['50']};
    background: #FFFFFF;
  }

  &.Mui-disabled {
    border-color: silver;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: 12px;
      padding: 10px;
    }
  `}
`;

export const Username = styled(Typography)`
  font-size: 15px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: 12px;
    }
  `}
`;
