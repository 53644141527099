import React from 'react';

import { LoginForm } from '@modules/auth';

const Login = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
