import { useEffect } from 'react';

import Header from '@components/Header';
import { Navigate, Outlet } from 'react-router';
import { useAppDispatch, useAppSelector } from '@app/store';
import SuspenseLoader from '@components/SuspenseLoader';
import { logout, setUser } from '@modules/auth';
import { useGetProfileQuery } from '@modules/account';
import { PUBLIC_PAGES } from '@app/router/pages';

import { LayoutContainer } from '@layouts/styled';

const AuthLayout = () => {
  const { accessToken, userInfo } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();
  const { data: userInfoData, isError } = useGetProfileQuery();

  useEffect(() => {
    if (isError) {
      dispatch(logout());
    }

    if (!userInfoData) {
      return;
    }

    dispatch(setUser({ user: userInfoData.data }));
  }, [dispatch, userInfo, isError]);

  if (!accessToken) {
    return <Navigate to={PUBLIC_PAGES.LOGIN} />;
  }

  if (!userInfo.user) {
    return <SuspenseLoader />;
  }

  return (
    <LayoutContainer>
      <Header />

      <Outlet />
    </LayoutContainer>
  );
};

export default AuthLayout;
