import { authSlice } from './store/auth.slice';
import { authApi } from './api/auth.api';
import LoginForm from './components/LoginForm';
import { IUser } from './types';

export const { logout, setUser } = authSlice.actions;

export type { IUser };

export { LoginForm, authApi, authSlice };
