import { DeleteProfile } from '@modules/account';

const MyAccount = () => {
  return (
    <>
      <DeleteProfile />
    </>
  );
};

export default MyAccount;
